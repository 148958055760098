<template>
  <b-row class="match-height">
    <b-col cols="12" style="margin-bottom:20px">
      <b-row>
        <b-col cols="3" v-if="this.pengguna.hak_akses == 'mabes'">
    <b-form-select v-model="selectedProv" :options="optionsProv" @change="getPolda(selectedProv);getChart(selectedProv,'provinsi');tipe_wilayah = 'provinsi';id_wilayah = selectedProv"></b-form-select>
  </b-col>
  <b-col cols="3" v-if="this.pengguna.hak_akses == 'mabes' || this.pengguna.hak_akses == 'polda'">
    <b-form-select v-model="selectedPolda" :options="options" @change="getPolres(selectedPolda);getChart(selectedPolda,'kabupaten');tipe_wilayah = 'kabupaten';id_wilayah = selectedPolda"></b-form-select>
  </b-col>
  <b-col cols="3">
    <b-form-select v-model="selectedPolres" :options="optionsPolres" @change="getChart(selectedPolres,'kecamatan');tipe_wilayah = 'kecamatan';id_wilayah = selectedPolres"></b-form-select>
  </b-col>
        <b-col cols="3">
    <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        :href="'https://sembako-backend.denican.id/stok_export?tipe_wilayah='+tipe_wilayah+'&id_wilayah='+id_wilayah"
        
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        <span class="align-middle">Download Excel</span>
      </b-button>
  </b-col>
      </b-row>
    </b-col>
  
  <b-col cols="12">
    <b-card title="Harga Komoditi per Hari ini">
      <vue-apex-charts ref="sample" type="line" height="350" :options="chartOptions" :series="series" @click="clickHandler"></vue-apex-charts>
    </b-card>
  </b-col>

  <b-col cols="12">
    <b-card v-if="namaKomoditi != null">
      <h3>Pemantauan Harga Komoditi <strong> {{this.namaKomoditiAsli}} </strong> dalam 2 minggu terakhir</h3>
      <vue-apex-charts ref="sampleDetail" type="line" height="350" :options="chartOptionsDetail" :series="seriesDetail"></vue-apex-charts>
    </b-card>
  </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BLink, BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,BFormSelect,BButton} from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  components: {
    VueApexCharts,
    BRow,
    BCol,
    BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BFormSelect,
    BButton,
    BCardSubTitle
  },
  data() {
    return {
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      namaKomoditi: null,
      namaKomoditiAsli: null,
      selectedPolda:null,
      selectedProv:null,
      selectedPolres:null,
      tipe_wilayah: null,
      id_wilayah:null,
      dataHarga:[],
      dataHet:[],
      dataJenis:[],
      optionsProv: [
          { value: null, text: 'Please select an option' }
      ],
      options: [
          { value: null, text: 'Please select an option' }
      ],
      optionsPolres: [
          { value: null, text: 'Please select an option' }
      ],
      
      series: [{
            name: 'Harga',
            type: 'column',
            data: []
          },  {
            name: 'Het',
            type: 'line',
            data: []
          }],
          seriesDetail: [{
            name: 'Harga',
            type: 'column',
            data: []
          },  {
            name: 'Het',
            type: 'line',
            data: []
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
            },
            stroke: {
              width: [0, 4],
            },
            plotOptions: {
              bar: {
                columnWidth: '50%'
              }
            },
            labels: ['unden'],
            markers: {
              size: 0
            },
            xaxis: {
              type: 'text'
            },
            yaxis: {
              title: {
                text: 'Rupiah',
              },
              min: 0
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(0) + " Rupiah";
                  }
                  return y;
            
                }
              }
            }
          },
          chartOptionsDetail: {
            chart: {
              height: 350,
              type: 'line',
            },
            stroke: {
              width: [0, 4],
            },
            plotOptions: {
              bar: {
                columnWidth: '50%'
              }
            },
            labels: ['unden'],
            markers: {
              size: 0
            },
            xaxis: {
              type: 'text'
            },
            yaxis: {
              title: {
                text: 'Rupiah',
              },
              min: 0
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(0) + " Rupiah";
                  }
                  return y;
            
                }
              }
            }
          },
    }
  },
    methods:{
    async loadData(){
      await axios.get(this.url+'wilayah/provinsi')
      .then(response =>{
        var dataProvinsi = []
        dataProvinsi = response.data.data

        for(let i = 0; i<dataProvinsi.length;i++){
          this.optionsProv.push({
            value: dataProvinsi[i].id,
            text: dataProvinsi[i].nama
          })
        }
        
      })
    },
    async getPolda(id){
      this.options = []
      this.optionsPolres = []
      this.selectedPolda = null
      this.selectedPolres = null
      await axios.get(this.url+'wilayah/kabupaten?id_prov='+id)
      .then(response =>{
        var dataPolda = []
        dataPolda = response.data.data

        for(let i = 0; i<dataPolda.length;i++){
          this.options.push({
            value: dataPolda[i].id,
            text: dataPolda[i].nama
          })
        }
        
      })
    },
    async getPolres(id){
      this.optionsPolres = []
      this.selectedPolres = null
      await axios.get(this.url+'wilayah/kecamatan?id_kab='+id)
      .then(response =>{
        console.log(response)
        var dataPolres = []
        dataPolres = response.data.data

        for(let i = 0; i<dataPolres.length;i++){
          this.optionsPolres.push({
            value: dataPolres[i].id,
            text: dataPolres[i].nama
          })
        }
        
      })
    },
    async getChart(id,type){
      console.log(id)
      await axios.get(this.url+'stok_chart/harga?tipe_wilayah='+type+'&id_wilayah='+id, {
      headers: {
          
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.namaKomoditi = null
        console.log(response)
        var ser = this.chartOptions.labels;
        while (ser.length) {
          ser.pop();
        }
        // var dataHet =[]
        // var dataSeries = []
        var dataJenis = []
        
        this.$data.dataHarga = response.data.data.harga
        this.$data.dataHet = response.data.data.het
        dataJenis = response.data.data.jenis


        // this.chartOptions.xaxis.categories = kategori
        // this.series[0].data = dataSeries

        for (var i = 0; i < dataJenis.length; i++) {
            this.$data.chartOptions.labels.push(dataJenis[i]);
        }
        //this.$data.chartOptions.labels.splice(0, 1);

        this.$refs.sample.updateOptions({
            series: [{
              name: 'Harga',
              type: 'column',
              data: this.dataHarga
            },
            {
              name: 'HET',
              type: 'line',
              data: this.dataHet
            }
            
            ],
           
        })
      })
      .catch( error => {
      })
    },
    async clickHandler(event, chartContext, config){
        console.log(config.dataPointIndex)

        if(config.dataPointIndex == 0){
          this.namaKomoditi = "beras"
          this.namaKomoditiAsli = "Beras"
        }else if(config.dataPointIndex == 1){
          this.namaKomoditi = "minyak_goreng"
          this.namaKomoditiAsli = "Minyak Goreng"
        }else if(config.dataPointIndex == 2){
          this.namaKomoditi = "telur"
          this.namaKomoditiAsli = "Telur"
        }else if(config.dataPointIndex == 3){
          this.namaKomoditi = "gula"
          this.namaKomoditiAsli = "Gula"
        }else if(config.dataPointIndex == 4){
          this.namaKomoditi = "daging_ayam"
          this.namaKomoditiAsli = "Daging Ayam"
        }else if(config.dataPointIndex == 5){
          this.namaKomoditi = "daging_sapi"
          this.namaKomoditiAsli = "Daging Sapi"
        }else if(config.dataPointIndex == 6){
          this.namaKomoditi = "kedelai"
          this.namaKomoditiAsli = "Kedelai"
        }else if(config.dataPointIndex == 7){
          this.namaKomoditi = "cabe_merah"
          this.namaKomoditiAsli = "Cabe Merah"
        }else if(config.dataPointIndex == 8){
          this.namaKomoditi = "bawang_merah"
          this.namaKomoditiAsli = "Bawah Merah"
        }

        await axios.get(this.url+'stok_chart/harga/2minggu?tipe_wilayah='+this.tipe_wilayah+'&id_wilayah='+this.id_wilayah+'&komoditi='+this.namaKomoditi)
        .then(response => {
          console.log(response)
        var ser = this.chartOptionsDetail.labels;
        while (ser.length) {
          ser.pop();
        }
        var dataHetnya =[]
        var dataSeriesnya = []
        var dataJenis = []
        
        this.$data.seriesDetail[0].data = response.data.data.harga
        this.$data.seriesDetail[1].data = response.data.data.het
        dataJenis = response.data.data.tanggal

        

        // this.chartOptions.xaxis.categories = kategori
        // this.series[0].data = dataSeries

        for (var i = 0; i < dataJenis.length; i++) {
            this.$data.chartOptionsDetail.labels.push(dataJenis[i]);
        }

        this.$refs.sampleDetail.updateOptions({
            seriesDetail: [{
              name: 'Harga',
              type: 'column',
            },
            {
              name: 'HET',
              type: 'line',
            }
            
            ],
           
        })

        //console.log(this.$data.chartOptionsDetail.labels)
        //this.$data.chartOptions.labels.splice(0, 1);

        
        console.log(this.seriesDetail)
      })
      .catch( error => {
      })
    },
    cleanMingguan(){
      var ser = this.chartOptionsDetail.labels;
      while (ser.length) {
          ser.pop();
      }
    }
  },
  created (){
    this.loadData()
    if(this.pengguna.hak_akses == "mabes"){
      console.log("ini mabes")
    }else if(this.pengguna.hak_akses == "polda"){
      this.getPolda(this.pengguna.id_prov)
    }else if(this.pengguna.hak_akses == "polres"){
      this.getPolres(this.pengguna.id_kab)
    }
    
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
